<template lang="pug">
#loading.position-relative
  decoration-firework.position-absolute.decoration-firework-left(src="../assets/images/loading-sun-decoration.svg" :index="1")
  decoration-firework.position-absolute.decoration-firework-right(src="../assets/images/loading-sun-decoration.svg" :index="2")

  .container
    .row
      .col-12.d-flex.justify-content-center
        .thumbnail
          h1.logo 
            img(src="../assets/images/loading-logo.svg")
          p.hint 台灣啤酒為東京奧運中華代表團合作夥伴
          p.question 你是否已滿18歲?
          .flex-center
            .button-group
              router-link.button-link(:to="{ path: '/home' }" tag="button") YES
              router-link.button-link(:to="{ path: '/' }" tag="button") NO
    
  .hidden-preload-font-block 團員大募集

  bottom-banner
</template>

<script>
import BottomBanner from "@/components/BottomBanner";
import DecorationFirework from "@/components/DecorationFirework";

export default {
  name: "Loading",
  components: { BottomBanner, DecorationFirework },
};
</script>

<style lang="sass" scoped>
#loading
  width: 100vw
  height: 100vh
  min-height: auto
  padding-top: 24vh
  position: relative
  background-image: url("../assets/images/loading-background.jpg")
  background-size: cover
  background-position: center
  overflow: hidden

  .decoration-firework-left
    left: 0
    top: 0
    transform: rotate(-180deg)

  .decoration-firework-right
    bottom: 105px
    right: 0

  .logo
    text-align: center
    margin: 0 12px
    img
      width: 100%
      padding: 0 12px

  .thumbnail
    z-index: 1
    .hint
      text-align: center
      color: #ffffff
      font-size: 12px
      font-weight: normal
      margin-top: 24px

    .question
      font-weight: bold
      text-align: center
      font-size: 25px
      margin: 24px 0 36px
      color: #ffffff

    .button-group
      display: flex

      .button-link
        text-align: center
        display: block
        width: 200px
        color: #fdd351
        text-decoration: none
        font-family: "Noto Sans TC", sans-serif
        font-weight: bold
        font-size: 24px
        letter-spacing: 4px
        text-indent: 4px
        transition: all .5s
        background-image: url("../assets/images/loading-button.svg")
        background-size: contain
        background-repeat: no-repeat
        background-position: center
        height: 60px
        line-height: 60px
        margin: 0 4px

        &:hover
          color: inherit
          text-shadow: -1px -1px 0 #fdd351, 0 -1px 0 #fdd351, 1px -1px 0 #fdd351, 1px 0 0 #fdd351, 1px 1px 0 #fdd351, 0 1px 0 #fdd351, -1px 1px 0 #fdd351, -1px 0 0 #fdd351

  .hidden-preload-font-block
    font-family: "DFZongKaiStd", "DFMingStd", sans-serif
    visibility: hidden
    height: 0

  @media screen and (max-width: 1600px)
    padding-top: 16vh
    min-height: 900px
    .decoration-firework-left
      transform: scale(0.5) rotate(-180deg)
      top: -15%
      left: -135px
    .decoration-firework-right
      transform: scale(0.5)
      right: -135px
      bottom: -10%
  @media screen and (max-width: 1366px)
    padding-top: 12vh
    min-height: 768px
  @media screen and (max-width: 1200px)
    min-height: 800px
  @media screen and (max-width: 1024px)
    min-height: 768px
  @media screen and (max-width: 991.98px)
    padding-top: 20vh
    min-height: auto
    .thumbnail
      .question
        font-size: 20px
  @media screen and (max-width: 565.98px)
    .thumbnail
      .question
        font-size: 18px
      .button-group
        .button-link
          transform: scale(0.8)
  @media screen and (max-width: 452.98px)
    background-image: url("../assets/images/mobile-bg.jpg")
  @media screen and (max-width: 414.98px)
    .decoration-firework-left
      transform: scale(0.3) rotate(180deg)
      top: -28%
      left: -187px
    .decoration-firework-right
      transform: scale(0.3)
      right: -187px
      bottom: -20%
  @media screen and (max-width: 375.98px)
    .thumbnail
      color: red
      .button-group
        max-width: 320px
    .decoration-firework-left
      top: -37%
    .decoration-firework-right
      transform: scale(0.3)
      bottom: -27%
</style>
