<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="569.305"
    height="622.397"
    viewBox="0 0 569.305 622.397"
    id="decoration-firework"
    :class="'firework_' + index"
  >
    <defs>
      <clipPath id="prefix__a">
        <path
          data-name="Subtraction 1"
          d="M-11484.786 622.4H-11940V0h569.3v502.887a117.631 117.631 0 0 0-21.61 2.5 116.782 116.782 0 0 0-20.929 6.529 116.173 116.173 0 0 0-19.64 10.555 117.61 117.61 0 0 0-17.745 14.58 117.535 117.535 0 0 0-15.355 18.918 116.089 116.089 0 0 0-10.812 21.015 116.787 116.787 0 0 0-6.271 22.384 117.639 117.639 0 0 0-1.729 23.024z"
          transform="translate(13290.695 459.77)"
          fill="#dc06c3"
          stroke="#707070"
        ></path>
      </clipPath>
    </defs>
    <g
      data-name="Mask Group 50"
      transform="translate(-1350.695 -459.77)"
      clip-path="url(#prefix__a)"
    >
      <g data-name="Component 17 – 11">
        <g data-name="Group 293" opacity=".894">
          <g
            data-name="light"
            stroke-linecap="round"
            stroke-width="10"
            stroke-dasharray="0 33"
          >
            <path
              data-name="Line 86"
              fill="none"
              stroke="#fff16c"
              stroke-linejoin="round"
              d="M1923.067 1080l-512.545-26.976"
            ></path>
            <path
              data-name="Line 87"
              fill="none"
              stroke="#f519d3"
              stroke-linejoin="round"
              d="M1923.068 1080L1427.283 947.05"
            ></path>
            <path
              data-name="Line 88"
              fill="none"
              stroke="#fff16c"
              stroke-linejoin="round"
              d="M1923.067 1080l-457.355-233.11"
            ></path>
            <path
              data-name="Line 89"
              fill="none"
              stroke="#dc06c3"
              stroke-linejoin="round"
              d="M1923.067 1080L1524.13 756.916"
            ></path>
            <path
              data-name="Line 90"
              fill="none"
              stroke="#fff16c"
              stroke-linejoin="round"
              d="M1923.067 1080l-323.084-398.937"
            ></path>
            <path
              data-name="Line 91"
              fill="none"
              stroke="#dc06c3"
              stroke-linejoin="round"
              d="M1923.067 1080l-233.11-457.355"
            ></path>
            <path
              data-name="Line 92"
              fill="none"
              stroke="#fff16c"
              stroke-linejoin="round"
              d="M1923.067 1080l-132.948-495.785"
            ></path>
            <path
              data-name="Line 93"
              fill="none"
              stroke="#dc06c3"
              stroke-linejoin="round"
              d="M1923.067 1080l-26.976-512.545"
            ></path>
            <path
              data-name="Line 94"
              fill="none"
              stroke="#fff16c"
              stroke-linejoin="round"
              d="M1923.067 1080l80.175-506.906"
            ></path>
            <path
              data-name="Line 95"
              fill="none"
              stroke="#dc06c3"
              stroke-linejoin="round"
              d="M1923.067 1080l183.822-479.112"
            ></path>
            <path
              data-name="Line 96"
              fill="none"
              stroke="#fff16c"
              stroke-linejoin="round"
              d="M1923.067 1080l279.435-430.379"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
import { playLightAnimation } from "@/assets/animations/gsapAnimation";

export default {
  name: "DecorationFirework",
  props: {
    index: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    playLightAnimation(`firework_${this.index}`);
  },
};
</script>

<style scoped lang="scss">
#decoration-firework {
  path {
    opacity: 0.2;
    // animation: dash 5s linear alternate infinite;
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 0 500;
      stroke-opacity: 0;
    }
    50% {
      stroke-opacity: 1;
    }
    80% {
      stroke-opacity: 0.8;
    }
    100% {
      stroke-dasharray: 0 33;
      stroke-dashoffset: 60;
      stroke-opacity: 0;
    }
  }
}
</style>
